<div class="row">
    <div
        class="col-12 link-menu font-regular"
        [ngClass]="{ 'submenu-pr': type==1}"
        (click)="clickitem(item)"
    >
        {{item.title}}
    </div>

</div>
