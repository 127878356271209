/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./link-menu/link-menu.component.ngfactory";
import * as i3 from "./link-menu/link-menu.component";
import * as i4 from "../ad-translate-service.service";
import * as i5 from "@angular/common";
import * as i6 from "./block-user/block-user.component.ngfactory";
import * as i7 from "./block-user/block-user.component";
import * as i8 from "../utilityservice.service";
import * as i9 from "./menu.component";
import * as i10 from "@p2r/platform-api";
import * as i11 from "@angular/router";
var styles_MenuComponent = [i0.styles];
var RenderType_MenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuComponent, data: {} });
export { RenderType_MenuComponent as RenderType_MenuComponent };
function View_MenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "keepup-link-menu", [], null, [[null, "clickItemlink"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickItemlink" === en)) {
        var pd_0 = (_co.selectItem($event, _v.parent.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LinkMenuComponent_0, i2.RenderType_LinkMenuComponent)), i1.ɵdid(2, 114688, null, 0, i3.LinkMenuComponent, [i4.AdTranslateServiceService], { setitem: [0, "setitem"], settype: [1, "settype"] }, { clickItemlink: "clickItemlink" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = 1; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_3)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.submenu; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "keepup-link-menu", [], null, [[null, "clickItemlink"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickItemlink" === en)) {
        var pd_0 = (_co.selectItem($event, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LinkMenuComponent_0, i2.RenderType_LinkMenuComponent)), i1.ɵdid(2, 114688, null, 0, i3.LinkMenuComponent, [i4.AdTranslateServiceService], { setitem: [0, "setitem"], settype: [1, "settype"] }, { clickItemlink: "clickItemlink" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = 0; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.opensubmenu; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_MenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "w-100 pl-12 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "keepup-block-user", [], null, null, null, i6.View_BlockUserComponent_0, i6.RenderType_BlockUserComponent)), i1.ɵdid(4, 114688, null, 0, i7.BlockUserComponent, [i8.UtilityserviceService], { setitem: [0, "setitem"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "keepup-link-menu", [], null, [[null, "clickItemlink"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickItemlink" === en)) {
        var pd_0 = (_co.selectItem($event, (0 - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LinkMenuComponent_0, i2.RenderType_LinkMenuComponent)), i1.ɵdid(8, 114688, null, 0, i3.LinkMenuComponent, [i4.AdTranslateServiceService], { setitem: [0, "setitem"], settype: [1, "settype"] }, { clickItemlink: "clickItemlink" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_1)), i1.ɵdid(10, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "keepup-link-menu", [], null, [[null, "clickItemlink"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickItemlink" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LinkMenuComponent_0, i2.RenderType_LinkMenuComponent)), i1.ɵdid(12, 114688, null, 0, i3.LinkMenuComponent, [i4.AdTranslateServiceService], { setitem: [0, "setitem"], settype: [1, "settype"] }, { clickItemlink: "clickItemlink" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.itemhome; var currVal_2 = 0; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = _co.drawerMenu; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.itemlogout; var currVal_5 = 0; _ck(_v, 12, 0, currVal_4, currVal_5); }, null); }
export function View_MenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "keepup-menu", [], null, null, null, View_MenuComponent_0, RenderType_MenuComponent)), i1.ɵdid(1, 114688, null, 0, i9.MenuComponent, [i10.P2RApiService, i8.UtilityserviceService, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuComponentNgFactory = i1.ɵccf("keepup-menu", i9.MenuComponent, View_MenuComponent_Host_0, {}, { clickItem: "clickItem" }, []);
export { MenuComponentNgFactory as MenuComponentNgFactory };
