import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'keepup-empty-route',
  templateUrl: './keepup-empty-route.component.html',
  styleUrls: ['./keepup-empty-route.component.scss']
})
export class KeepupEmptyRouteComponent implements OnInit, OnDestroy {
  title: any = 'Hello p2r-keepup-app';
  sayHelloInput: any;
  private subscription: Subscription;

  constructor(

  ) {

  }

  ngOnInit() {

  }

  public sayHello() {

  }

  ngOnDestroy() {

  }
}
