/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alert.component";
var styles_AlertComponent = [i0.styles];
var RenderType_AlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
function View_AlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "image-container"], ["id", "image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["height", "80px"], ["width", "80px"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "vertical-space"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgSrc; _ck(_v, 2, 0, currVal_0); }); }
function View_AlertComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title-container fs34 font-medium"], ["id", "title-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "vertical-space"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
function View_AlertComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "font-r_l fs18 lh21 c11"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.class ? _v.context.$implicit.class : "message-container m-auto w-75"); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = (_v.context.$implicit.text || _v.context.$implicit); _ck(_v, 5, 0, currVal_1); }); }
export function View_AlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "d-flex flex-column"], ["style", "overflow: hidden; margin: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "message-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_3)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgSrc; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.messages; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_AlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "configuration-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i1.ɵccf("configuration-alert", i3.AlertComponent, View_AlertComponent_Host_0, { data: "data", imgSrc: "imgSrc", title: "title", messages: "messages" }, {}, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
