import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "configuration-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit {
  private _data: any;
  private _imgSrc: any;
  private _title: any;
  private _messages: any;

  constructor() {}

  ngOnInit() {}

  @Input() set data(data: any) {
    console.log(data);
    this._data = data;
  }

  get data(): any {
    return this._data;
  }

  @Input() set imgSrc(imgSrc: any) {
    this._imgSrc = imgSrc;
  }

  get imgSrc(): any {
    return this._imgSrc || this._data.imgSrc;
  }

  @Input() set title(title: any) {
    this._title = title;
  }

  get title(): any {
    return this._title || this._data.title;
  }

  @Input() set messages(messages: any[]) {
    this._messages = messages;
  }

  get messages(): any[] {
    return this._messages || this._data.messages;
  }
}
