<div
    class="row header"
    [ngClass]="{'header-dark':typeheader==1}"
>
    <div class="col-12 justify-content-between d-flex">
        <div
            class="align-items-center d-flex"
            (click)="toggleNavbar()"
        >
            <img
                class="img-24"
                [src]="iconmenu"
            >
            <ng-container *ngIf="typeheader==0">
                <div
                    class="test-header font-regular "
                    *ngIf="title!=''"
                >
                    <span>{{ date | date: 'dd.MM.yyyy'}}</span>
                    <span class="ml-32">{{title}} - Back office</span>
                </div>
            </ng-container>
            <ng-container *ngIf="typeheader==1">
                <div
                    class="test-header font-regular "
                    *ngIf="title!=''"
                >

                    <span>{{title | translate}}</span>
                </div>
            </ng-container>

        </div>
        <div
            class="d-flex"
            *ngIf="isFullScreen==false"
        >
            <img
                [src]="imgfullscreen"
                alt
                class="h-fit mt-auto mb-auto"
                (click)="openFullscreen()"
            >
        </div>
        <div
            class="d-flex"
            *ngIf="isFullScreen==true"
        >
            <img
                [src]="imgreductscreen"
                alt
                class="h-fit mt-auto mb-auto"
                (click)="closeFullscreen()"
            >

        </div>

    </div>
</div>
