/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../node_modules/ngx-ui-loader/ngx-ui-loader.ngfactory";
import * as i4 from "ngx-ui-loader";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./app.component";
import * as i7 from "./utilityservice.service";
import * as i8 from "./ad-translate-service.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "bootstrap-stylesheet core-ui-stylesheet d-flex flex-column flex-grow-1 h-100 strangler "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ngx-ui-loader", [], null, null, null, i3.View_ɵb_0, i3.RenderType_ɵb)), i1.ɵdid(4, 770048, null, 0, i4.ɵb, [i5.DomSanitizer, i1.ChangeDetectorRef, i4.NgxUiLoaderService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "adminmenu-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i6.AppComponent, [i7.UtilityserviceService, i1.NgZone, i8.AdTranslateServiceService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("adminmenu-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
