import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { P2RApiService, P2R_CENTER_DETT } from "@p2r/platform-api";
import { catchError, tap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { Router } from "@angular/router";

@Component({
  selector: "keepup-rigenera-cassa-history",
  templateUrl: "./rigenera-cassa-history.component.html",
  styleUrls: ["./rigenera-cassa-history.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RigeneraCassaHistoryComponent implements OnInit {
  rows;
  ColumnMode = ColumnMode;
  sf: Array<any> = [];

  constructor(
    private p2rApiService: P2RApiService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router
  ) { }

  ngOnInit() {
    this.ngxLoader.start();
    let logrc = this.p2rApiService
      .get<any[]>(
        this.p2rApiService.apiPath + "/core/LOGRC",
        {
          source: "settings-25",
          center_dett: P2R_CENTER_DETT.ALL_VIEWS,
          offset: "all",
        },
        {
          microservice: "mssettings",
        }
      )
      .pipe(
        catchError((error) => of(error)),
        tap((v: any) => { })
      );
    let sfood = this.p2rApiService
      .get(this.p2rApiService.apiPath + "/settings/SFFOOD", {
        source: "settings-25",
        center_dett: P2R_CENTER_DETT.ALL_VIEWS,
        offset: "all",
      })
      .pipe(
        catchError((error) => of(error)),
        tap((v: any) => { })
      );

    forkJoin([logrc, sfood]).subscribe((response: any) => {
      this.ngxLoader.stopAll();
      if (response[0] !== undefined && !response[0].status) {
        this.rows = response[0];
        this.sf = response[1];
        this.rows.sort(function (a, b) {
          return b.XLOGRC07 - a.XLOGRC07;
        });
        this.rows = this.rows.map((e) => {
          e.XLOGRC07;
          let d = new Date(e.XLOGRC07 * 1000);
          e.XLOGRC07 = d.toLocaleTimeString();

          const dateString = e.XLOGRC05;
          const year = dateString.substr(0, 4);
          const month = (dateString.substr(4, 2) as any) * 1;
          const day = dateString.substr(6, 2);

          e.XLOGRC05 = day + "/" + month + "/" + year;
          let selectsf = this.sf.filter((x) => {
            if (x.XSF03 == e.XLOGRC03_COD && x.XSF04 == e.XLOGRC04) {
              return x;
            }
          })[0];
          if (selectsf != undefined)
            e.XLOGRC04 =
              selectsf.XSFFOOD05 != undefined
                ? selectsf.XSFFOOD05
                : selectsf.XSF06;
          return e;
        });
        console.log(this.rows);
        // console.log('LOGRC = \n' + JSON.stringify(response));
      }
    });
  }

  back() {
    this.router.navigateByUrl("/genera-cassa");
  }
}
