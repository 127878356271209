<div class="row box-user">

    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <img [src]="imagelogo">
            </div>
        </div>
        <!-- <div class="row">
      <div class="col-12">
        <div class="back-office font-regular">
          {{ 'back_office' | translate }}
        </div>
      </div>
    </div> -->

    </div>
    <div class="col-12 box-username">
        <div class="row username">
            <div class="  userimag d-flex">
                <img
                    class="w-100"
                    [src]="avatar"
                >
            </div>

            <div class=" col-8   ">
                <div class="w-100 welcomeuser font-light">{{'welcome' | translate }}</div>
                <div class="w-100 name-cognome font-medium">{{user.nome_cognome}}</div>

            </div>

        </div>

    </div>
    <div class="col-12 box-zoom d-flex justify-content-between">
        <div class="zoom-text font-medium text-uppercase">{{'zoom' |translate}}</div>
        <div class="zoom-text font-medium d-flex zoom-perc">
            <p
                (click)="setzoom(-1)"
                class="test-percent cursor-pointer"
            >
                -
            </p>
            <span class="test-percent">{{perczoom+'%'}}</span>
            <p
                class="test-percent cursor-pointer"
                (click)="setzoom(1)"
            >
                +
            </p>
        </div>

    </div>

</div>
