<div [id]="id">
    <div
        class="modal-header"
        [ngClass]="{'d-none': hasHead !== undefined && hasHead === false}"
    >
        <h6
            class="modal-title font-medium"
            [ngClass]="titleClasses"
        >
            {{title}}&nbsp;
        </h6>
        <button
            type="button"
            class="close"
            [ngClass]="{'d-none': hideCloseButton}"
            aria-label="Close"
            (click)="dismiss($event)"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div
        class="modal-body"
        [ngClass]="{'d-none': body == undefined,
                    'modal-body-scroll': body ? true : false }"
    >
        <div #bodyContainer></div>
    </div>
    <div class="modal-footer  keepup-modal ">
        <div
            class="d-flex  w-100"
            [ngClass]="[numberAction>1?'justify-content-between plr-64':'justify-content-center']"
            *ngFor="let classification of classificatedActions | keyvalue; let i = index"
        >
            <div
                *ngFor="let action of classification.value"
                [ngClass]="{'mr-1': i === 0}"
            >
                <button
                    type="button"
                    (click)="action.click()"
                    class="btn btn-custom btm-150 font-light"
                >
                    {{action.label}}
                </button>
            </div>
        </div>
    </div>
</div>
