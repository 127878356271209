import { Component, OnInit } from "@angular/core";
import { NavigationEnd } from "@angular/router";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilityserviceService } from "../utilityservice.service";
import { filter } from "rxjs/operators";
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { UtilisShards } from "./utils/UtilsShared";

@Component({
  selector: "keepup-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {
  public iconmenu: string;
  disabledheder: boolean;
  private arraylist = ["settings", "userfood", "configuration"];
  imgfullscreen: string;
  imgreductscreen: string;
  isFullScreen: boolean;
  elem: any;
  name: any;
  private _name: any;
  public date = new Date();
  typeheader: number;

  constructor(
    private utilityserviceService: UtilityserviceService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private doc: any
  ) {
    this.elem = this.doc.documentElement;
    this.imgfullscreen = this.utilityserviceService.getImg(
      "icons/fullscreen-icon.svg"
    );
    this.imgreductscreen = this.utilityserviceService.getImg(
      "icons/windowscreen-icon.svg"
    );
    this.isFullScreen = document.fullscreenElement ? true : false;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url == event.urlAfterRedirects) {
          if (this.arraylist.includes(event.url.split("/")[1])) {
            this.disabledheder = true;
            this.name = "";
          } else {
            if (
              event.url.split("/")[1] == "" ||
              event.url.split("/")[1] == "home" ||
              event.url.split("/")[1] == undefined ||
              event.url.split("/")[1] == "genera-cassa"
            ) {
              this.name = this.utilityserviceService.name;
              this.typeheader = 0;
              if (event.url.split("/")[1] == "genera-cassa") {
                this.name = "genera-cassa";
                this.typeheader = 1;
              }
            } else {
              this.name = "";
            }

            this.disabledheder = false;
          }
        }
      });
    this.iconmenu = this.utilityserviceService.getImg("icons/menu_1.png");
    document.removeEventListener(
      "SETTINGS:OPEN_FULL_SCREEN",
      this.openFullScreen.bind(this)
    );

    document.addEventListener(
      "SETTINGS:OPEN_FULL_SCREEN",
      this.openFullScreen.bind(this)
    );

    document.removeEventListener(
      "SETTINGS:OPEN_MENU",
      this.openmenu.bind(this)
    );

    document.addEventListener("SETTINGS:OPEN_MENU", this.openmenu.bind(this));

    document.removeEventListener(
      "SETTINGS:CLOSE_MENU",
      this.closemenu.bind(this)
    );

    document.addEventListener("SETTINGS:CLOSE_MENU", this.closemenu.bind(this));

    document.removeEventListener(
      "SETTINGS:CLOSE_FULL_SCREEN",
      this.closeFullScreen.bind(this)
    );

    document.addEventListener(
      "SETTINGS:CLOSE_FULL_SCREEN",
      this.closeFullScreen.bind(this)
    );
  }
  openFullScreen() {
    this.isFullScreen = true;
  }
  closeFullScreen() {
    this.isFullScreen = false;
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.navbarOpen = true;
      this.name = data.container;
      this._name = data.container;
      if (this.name == "genera-cassa") {
        this.typeheader = 1;
      }

      console.log(this.name, "sdad");
      /* console.log(data.container.segments[0].path)
        this.disabledheder=false
        if(data.container.segments[0].path=='settings'){
          this.disabledheder=true
        } */
    });
  }
  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  closemenu() {
    this.navbarOpen = false;
  }
  openmenu() {
    this.navbarOpen = true;
  }
  openFullscreen() {
    UtilisShards.openFullscreen(this.doc.documentElement);
    this.isFullScreen = true;
  }
  closeFullscreen() {
    UtilisShards.closeFullscreen(this.doc);
    this.isFullScreen = false;
  }
}
