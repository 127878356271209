<div class="form-body container-fluid h-100 rigenera">

    <div class="row h-100">
        <div class="form-holder h-auto">
            <div
                class="form-content h-100"
                style="display: flex;"
            >
                <div class="form-items h-100">
                    <!-- <div style="display: flex;">
            <h3 style="text-align: center; margin: auto;">{{'recreate_desk' | translate}}</h3>
            <button id="submit" type="submit"
                style="margin-top: 1px; margin-right: 2px; position:absolute; top:0; right:0;" (click)="showHistory()"
                class="btn">
                {{'show_history' | translate}}
              </button>
          </div> -->
                    <form class="d-flex flex-grow-1 h-100 ">

                        <div class="col-4 text-center p-3 border-end d-flex  flex-column   colstep">
                            <div class="step-into font-regular m-0">
                                <div class="numstep">1</div>
                                <div class="lablestep font-regular">
                                    <label for="store">{{'select-store' | translate}}</label>
                                </div>

                            </div>
                            <div
                                class="step-action "
                                *ngIf="storeList.length==0"
                            >

                                <ng-select
                                    style="margin-top: 20px;"
                                    [(ngModel)]="storeSelected"
                                    name="store"
                                    [items]="storeList"
                                    [searchable]="false"
                                    bindLabel="nome"
                                    bindValue="store"
                                    autofocus
                                >
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-item$="item$"
                                        let-index="index"
                                    >
                                        {{item.nome}}
                                    </ng-template>

                                </ng-select>
                                <div class="step-action ">
                                    <button
                                        id="submit2"
                                        type="button"
                                        style="margin-top: 20px;"
                                        (click)="storeSelectedChange()"
                                        class="btn btn-custom btm-150 text-uppercase"
                                        [disabled]="storeSelected==''"
                                    >
                                        {{'confirm' | translate}}
                                    </button>
                                </div>
                            </div>
                            <div
                                class="step-action "
                                *ngIf="storeList.length>0"
                            >

                                <ng-select
                                    style="margin-top: 20px;"
                                    [(ngModel)]="storeSelected"
                                    class="font-regular"
                                    [searchable]="false"
                                    [clearable]="false"
                                    name="store"
                                    [items]="storeList"
                                    bindLabel="nome"
                                    bindValue="store"
                                    autofocus
                                >
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-item$="item$"
                                        let-index="index"
                                    >
                                        <div class="d-flex">
                                            <div class=" div-select d-flex">
                                                <img
                                                    *ngIf="item$.selected"
                                                    [src]="imagselect"
                                                    style="width: 14px; margin: auto;"
                                                >
                                            </div>
                                            {{item.nome}}
                                        </div>

                                    </ng-template>
                                </ng-select>
                                <div class="step-action ">
                                    <button
                                        id="submit2"
                                        type="button"
                                        style="margin-top: 20px;"
                                        (click)="storeSelectedChange()"
                                        class="btn btn-custom btm-150 text-uppercase"
                                        [disabled]="storeSelected==''"
                                    >
                                        {{'confirm' | translate}}
                                    </button>
                                </div>
                            </div>
                            <div class="step-image mt-auto ">

                                <img [src]="imagnegozio">
                            </div>

                        </div>
                        <div
                            class="col-4 text-center p-3 border-end d-flex  flex-column   colstep"
                            [ngClass]="{'disabled-step':
              deskList.length==1}"
                        >
                            <div class="step-into font-regular">
                                <div class="numstep">2</div>
                                <div class="lablestep font-regular">
                                    <label for="desk">{{'select-desk' | translate}}</label>
                                </div>
                            </div>
                            <div class="step-action">
                                <!-- <select name="desk" [(ngModel)]="deskSelected" style="margin-top: 10px; margin-bottom: 10px;"
                  class="form-control">
                  <option value>--- {{'select-desk' | translate}} ---</option>
                  <option *ngFor="let item of this.deskList">{{item}}</option>
                </select> -->
                                <ng-select
                                    [(ngModel)]="deskSelected"
                                    class="font-regular"
                                    [clearable]="false"
                                    style="margin-top: 20px;"
                                    [searchable]="false"
                                    name="desk"
                                    [items]="this.deskList"
                                    autofocus
                                    bindLabel="nomecassa_view"
                                    bindValue="cassa"
                                    [disabled]="deskList.length==1"
                                >
                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-item$="item$"
                                        let-index="index"
                                    >
                                        <div class="d-flex">
                                            <div class=" div-select d-flex">
                                                <img
                                                    *ngIf="item$.selected&& item.cassa!=0 "
                                                    [src]="imagselect"
                                                    style="width: 14px; margin: auto;"
                                                >
                                            </div>
                                            {{item.nomecassa_view}}
                                        </div>

                                    </ng-template>

                                </ng-select>
                                <div class="step-action ">
                                    <button
                                        id="submit"
                                        type="submit"
                                        style="margin-top: 20px;"
                                        (click)="createCassa()"
                                        class="btn btn-custom btm-150 text-uppercase"
                                        [disabled]="deskSelected==''"
                                    >
                                        {{'generate' | translate}}
                                    </button>
                                </div>

                            </div>
                            <div class="step-image mt-auto ">

                                <img [src]="imagcassa">
                            </div>

                        </div>
                        <div
                            class="form-button col-4 text-center p-3 d-flex  flex-column colstep"
                            [ngClass]="{'disabled-step':
              !deskToken}"
                        >
                            <div class="step-into font-regular">
                                <div class="numstep">3</div>
                                <div class="lablestep font-regular">
                                    <label>{{'install_cashier' | translate}}</label>
                                </div>
                            </div>
                            <div class="step-action">
                                <div style="margin-top: 20px;">
                                    <div
                                        class="step-link-text form-control font-16-regular"
                                        *ngIf="!this.deskToken"
                                    >
                                        {{'link'| translate}}
                                    </div>
                                    <div
                                        class="step-link-text form-control font-medium"
                                        *ngIf="this.deskToken"
                                    >
                                        {{this.deskToken}}
                                    </div>

                                    <div style="display: flex; margin-top: 20px;">
                                        <div
                                            style="width: 200px; height:  200px; "
                                            class="border font-16-regular text-center d-flex m-auto"
                                            *ngIf="!this.deskToken"
                                        >
                                            <p class="m-auto">{{'qr_code'| translate}}</p>
                                        </div>
                                        <qr-code
                                            [value]="deskToken"
                                            size="200"
                                            style="margin: auto;"
                                            *ngIf="this.deskToken"
                                        ></qr-code>
                                    </div>
                                    <div style="display: flex;margin-top: 12px;">
                                        <button
                                            style="margin:auto;"
                                            class="btn btn-custom btm-150 text-uppercase"
                                            (click)="copyDeskToken()"
                                            [disabled]="!this.deskToken"
                                        >
                                            {{'copy_token' | translate}}
                                        </button>
                                    </div>
                                    <div style="display: flex;margin-top: 12px;">
                                        <button
                                            style="margin:auto;"
                                            class="btn btn-custom btm-150 text-uppercase"
                                            (click)="downloadAsPDF()"
                                            [disabled]="!this.deskToken"
                                        >
                                            {{'stampa_token' | translate}}
                                        </button>
                                    </div>

                                </div>
                            </div>
                            <div class="step-image mt-auto ">

                                <img [src]="imaglink">
                            </div>

                        </div>

                    </form>

                </div>
            </div>
        </div>
    </div>
</div>

<div
    id="pdfTable"
    #pdfTable
    class="flex-column d-flex rigenera"
    style="height: 650px; "
>

    <!-- src="https://qualitymultistore2.labketch-app.it/customSettings/webapp/custom/stile/default/img/login/logoLogin.png" -->
    <div class="logo-qr-code mb-3">
        <img [src]="imagelogo">
    </div>
    <div class="negozio mb-2 font-regular">
        {{'store' | translate}} :
        <span class="font-medium">{{storeSelectedlabel}}</span>
    </div>
    <div class="cassa mb-3 font-regular">
        {{'cassa' | translate}} :
        <span class="font-medium">{{deskSelectedlabelShow}}</span>
    </div>
    <div class="istruzioni-cassa-qr-code  font-subtitle font-regular mb-3">{{'istruzioni-cassa' | translate}}</div>

    <qr-code
        [value]="deskToken"
        size="150"
        class="istruzioni-qr-code"
    ></qr-code>
    <div class="font-subtitle font-regular mt-3">
        {{'date_time' | translate}}: {{data | date :'dd/MM/yyyy hh:mm'}}
    </div>
    <div class="mt-auto">
        <img [src]="imagelogoCustom">
    </div>

</div>
