import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdTranslateServiceService } from "src/app/ad-translate-service.service";
import { Menurigenera } from "src/app/dto/menu-rig.model";

@Component({
  selector: "keepup-rigenera-cassa-layout",
  templateUrl: "./rigenera-cassa-layout.component.html",
  styleUrls: ["./rigenera-cassa-layout.component.scss"],
})
export class RigeneraCassaLayoutComponent implements OnInit {
  properties: Array<Menurigenera> = [];
  constructor(
    private adTranslateServiceService: AdTranslateServiceService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    let rigenera: Menurigenera = {} as Menurigenera;
    rigenera.key = "genera";
    rigenera.isSelected = false;
    rigenera.label = this.adTranslateServiceService.get("generate");
    rigenera.url = "/genera-cassa";
    this.properties.push(rigenera);
    let rigeneraHistory: Menurigenera = {} as Menurigenera;
    rigeneraHistory.key = "rigenera-history";
    rigeneraHistory.isSelected = false;
    rigeneraHistory.label = this.adTranslateServiceService.get(
      rigeneraHistory.key
    );
    rigeneraHistory.url = "/genera-cassa/history";
    this.properties.push(rigeneraHistory);
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      console.log(data);
      let n = data.container.length - 1;

      this.properties[n].isSelected = true;
    });
  }
  onSelectProperty(property) {
    this.properties = this.properties.map((e) => {
      e.isSelected = e.key == property.key;
      return e;
    });
    this.router.navigateByUrl(property.url);
  }
}
