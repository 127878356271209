import { zip } from "rxjs";
import { map } from "rxjs/operators";
import { assetUrl } from "src/single-spa/asset-url";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@p2r/env";
import * as i3 from "ngx-webstorage";
export function HttpLoaderFactory(http, environment) {
    return new P2RTranslationLoader(http, environment);
}
export class P2RTranslationLoader {
    constructor(http, environment) {
        this.http = http;
        this.environment = environment;
    }
    getTranslation(lang) {
        return zip(this.http.get(assetUrl(`i18n/${lang}.json`, this.environment.getEnvironment().BASE_CONFIG_URL))).pipe(map(([t1, t2]) => (Object.assign({}, t1, t2))));
    }
}
export class AdTranslateServiceService {
    constructor(translate, p2rEnvironmentService, localStorageService) {
        this.translate = translate;
        this.p2rEnvironmentService = p2rEnvironmentService;
        this.localStorageService = localStorageService;
        this.environment = this.p2rEnvironmentService.getEnvironment();
        let lang = (window.sessionStorage.getItem("lingua") &&
            window.sessionStorage.getItem("lingua").toLocaleLowerCase()) ||
            (this.localStorageService.retrieve("lingua") &&
                this.localStorageService.retrieve("lingua").toLocaleLowerCase()) ||
            this.environment.DEFAULT_LANG ||
            this.translate.getBrowserLang();
        if (lang === "us") {
            lang = "en";
        }
        this.translate.setDefaultLang(lang);
    }
    useLanguage(language) {
        this.translate.use(language);
    }
    onLanguageChange$(key, interpolateParams) {
        return this.translate.getStreamOnTranslationChange(key, interpolateParams);
    }
    get(string) {
        return this.translate.instant(string);
    }
}
AdTranslateServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdTranslateServiceService_Factory() { return new AdTranslateServiceService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.P2REnvironmentService), i0.ɵɵinject(i3.LocalStorageService)); }, token: AdTranslateServiceService, providedIn: "root" });
