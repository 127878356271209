<div class="row h-100">
    <div class="col-12">
        <div class="h-100  container-welcome">
            <div
                class="h-100  body-welcome"
                [ngStyle]="{'background-image': 'url('+imag+')'}"
            >
                <div class="center-welcome">
                    <div class="name-rist font-medium mb-32">{{'ristorante' | translate}} {{name}}</div>
                    <div class="image-welcome mb-32">
                        <img
                            [src]="imglogoWelcome"
                            class="w-100 h-100"
                        >
                    </div>
                    <div class="back-office font-medium">{{ 'back_office' | translate }}</div>
                </div>

            </div>
        </div>
    </div>
</div>
