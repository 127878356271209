import { User } from "./../../dto/users.model";
import { Component, Input, OnInit } from "@angular/core";
import { UtilityserviceService } from "src/app/utilityservice.service";

@Component({
  selector: "keepup-block-user",
  templateUrl: "./block-user.component.html",
  styleUrls: ["./block-user.component.scss"],
})
export class BlockUserComponent implements OnInit {
  user: User = {} as User;
  perczoom = 80;
  imagelogo: string = "";
  avatar: string;
  zoomLevel: number = 1;
  @Input("user")
  set setitem(value: User) {
    console.log(value);
    if (value != undefined) {
      this.user = value;
    }
  }
  constructor(private utilityService: UtilityserviceService) {
    this.user.nome_cognome = "";
    this.setzoom(0);
    this.imagelogo = this.utilityService.getImg("logo-menu-BO.svg");
    this.avatar = this.utilityService.getImg("custom_avatar_icon.png");
  }

  ngOnInit() { }
  setzoom(value) {
    this.perczoom = this.perczoom + value * 10;
    this.zoomLevel = this.zoomLevel + value / 10;
    const elem = document.getElementsByTagName("adminmenu-root")[0] as any;

    elem.style.zoom = this.zoomLevel.toString();
    // Mando evento di resize in modo che gli altri componenti in ascolto possano
    // gestire l'evento di zoom (ad esempio, in scorecards vanno ridisegnati i grafici)
    console.log("ZOOM: mando evento di resize");
    window.dispatchEvent(new Event('resize'));
  }
}
