import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { P2RApiService, P2R_CENTER_DETT } from "@p2r/platform-api";
import { catchError, tap } from "rxjs/operators";
import { of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router } from "@angular/router";
import { UtilityserviceService } from "../utilityservice.service";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { AdTranslateServiceService } from "../ad-translate-service.service";
import { P2RDialogComponent } from "../shared/admin-dialog/p2r-dialog.component";
import { AlertComponent } from "../shared/alert/alert.component";
import { P2RDialogService } from "../shared/admin-dialog/p2r-dialog.service";

@Component({
  selector: "keepup-rigenera-cassa",
  templateUrl: "./rigenera-cassa.component.html",
  styleUrls: ["./rigenera-cassa.component.scss"],
})
export class RigeneraCassaComponent implements OnInit {
  public storeDeskList: any = [];
  public storeList: any = [];
  public deskList: any = [];
  public storeSelected: any;
  public deskSelected: any;
  public storeSelectedlabel: any = "";
  public deskSelectedlabel: any;
  public deskSelectedlabelShow: any;
  public deskMacAddressSelected: any;
  @ViewChild("pdfTable", { static: false }) pdfTable: ElementRef;
  public data = new Date();

  public deskToken: any;
  public aloderstroreall = true;
  list2: Array<string> = [];
  loderstroreall: boolean;
  imagnegozio: string;
  imagcassa: string;
  imaglink: string;
  imagelogo: string;
  closeResult: string;
  imagelogoCustom: string;
  imagselect: string;

  constructor(
    private p2rApiService: P2RApiService,
    private http: HttpClient,
    private ngxLoader: NgxUiLoaderService,
    private utilityService: UtilityserviceService,
    private router: Router,
    private adTranslateServiceService: AdTranslateServiceService,
    private p2rDialogService: P2RDialogService
  ) {
    this.imagnegozio = this.utilityService.getImg("rigenera_cassa_negozio.svg");
    this.imagcassa = this.utilityService.getImg("rigenera_cassa_cassa.svg");
    this.imaglink = this.utilityService.getImg("rigenera_cassa_link.svg");
    this.imagselect = this.utilityService.getImg("option_selected.svg");
    this.imagelogo =
      "/customSettings/webapp/custom/stile/default/img/login/logoKeepUp.svg";
    this.imagelogoCustom =
      "/customSettings/webapp/custom/stile/default/img/login/APK_logo_custom.svg";
  }

  ngOnInit() {
    this.ngxLoader.start();
    this.loderstrore();
  }
  private loderstrore() {
    this.deskSelected = "";
    this.p2rApiService
      .get(this.p2rApiService.apiPath + "/configuration/store/getStoreInfo")
      .pipe(
        catchError((error) => of(error)),
        tap((v: any) => { })
      )
      .subscribe((pvList: any) => {
        if (pvList !== undefined && !pvList.status) {
          this.p2rApiService
            .get(this.p2rApiService.apiPath + "/settings/SFFOOD", {
              source: "settings-25",
              center_dett: P2R_CENTER_DETT.ALL_VIEWS,
              offset: "all",
            })
            .pipe(
              catchError((error) => of(error)),
              tap((v: any) => { })
            )
            .subscribe((response: any) => {
              if (response !== undefined && !response.status) {
                pvList.pv.forEach((pv) => {
                  response.forEach((element) => {
                    if (element.XSF03 == pv.codice) {
                      this.storeDeskList.push({
                        store: element.XSF03,
                        nome: pv.nome,
                        cassa: element.XSF04,
                        nomecassa_view:
                          element.XSFFOOD05 != undefined
                            ? element.XSFFOOD05
                            : "cassa " + element.XSF04,
                        nomecassa:
                          element.XSFFOOD05 != undefined
                            ? element.XSFFOOD05
                            : element.XSF06,
                        mac_address: element.XSF06,
                      });
                      if (!this.storeExists(element.XSF03)) {
                        this.storeList.push({
                          store: element.XSF03,
                          nome: pv.nome,
                        });
                        this.list2.push(pv.nome);
                      }
                    }
                  });
                });
                this.storeDeskList.sort(function (a, b) {
                  if (a.store !== b.store) {
                    return a.store - b.store;
                  } else {
                    return a.cassa - b.cassa;
                  }
                });
                var firstEl = this.storeDeskList[0];
                if (firstEl !== undefined) {
                  /*
                  this.storeDeskList.forEach(element => {
                    if (element.store === firstEl.store) {
                      this.deskList.push(element.cassa);
                    }
                  });
                  */

                  this.storeSelected = firstEl.store;
                }
                this.deskList = [];
                let elem = {
                  store: 0,
                  nome: 0,
                  cassa: "0",
                  nomecassa: this.adTranslateServiceService.get("choose_cash_desk"),
                  nomecassa_view: this.adTranslateServiceService.get("choose_cash_desk"),
                  mac_address: "",
                };
                this.deskList.push(elem);
                this.deskSelected = "0";
                console.log(this.storeDeskList, response);
                this.loderstroreall = false;
                this.ngxLoader.stopAll();
              } else {
                console.error("There was an error!", response);
              }
            });
        } else {
          console.error("There was an error!", pvList);
        }
      });
  }

  storeExists(store) {
    return this.storeList.some(function (el) {
      return el.store === store;
    });
  }

  storeSelectedChange() {
    this.deskList = [];
    this.deskSelected = "";
    let elem = {
      store: 0,
      nome: 0,
      cassa: 0,
      nomecassa: this.adTranslateServiceService.get("choose_cash_desk"),
      nomecassa_view: this.adTranslateServiceService.get("choose_cash_desk"),
      mac_address: 0,
    };
    this.deskSelected = 0;
    this.deskList.push(elem);
    this.storeDeskList.forEach((element) => {
      if (element.store === this.storeSelected) {
        this.deskList.push(element);
      }
    });
  }

  showHistory() {
    this.router.navigateByUrl("/genera-cassa/history");
  }

  copyDeskToken() {
    if (this.deskToken) {
      const selBox = document.createElement("textarea");
      selBox.style.position = "fixed";
      selBox.style.left = "0";
      selBox.style.top = "0";
      selBox.style.opacity = "0";
      selBox.value = this.deskToken;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand("copy");
      document.body.removeChild(selBox);
      console.log("Token copied!");
      this.openCopyLink();
    }
  }

  createCassa() {
    console.log(this.storeSelected, this.deskSelected);
    if (this.storeSelected !== undefined && this.deskSelected) {
      this.deskMacAddressSelected = undefined;
      this.storeDeskList.forEach((element) => {
        if (
          element.store == this.storeSelected &&
          element.cassa == this.deskSelected
        ) {
          this.deskMacAddressSelected = element.mac_address;
          this.deskSelectedlabel = element.nomecassa;
          this.storeSelectedlabel = element.nome;
          this.deskSelectedlabelShow = element.nomecassa_view;
        }
      });
      if (this.deskMacAddressSelected) {
        this.deskToken = undefined;
        this.openrigenera();
        /*       this.recreateDesk1(
          this.storeSelected,
          this.deskSelected,
          this.deskMacAddressSelected
        ); */
      }
    }
  }

  recreateDesk1(Store, Num_Cassa, Mac_Address) {
    /* this.deskToken =
      "http://ls.p2r.it/application/setup.php?__t=1497541614995&__tkn=eyJtYWNhZGRyZXNzIjoiU3RvcmUzOTAzMF8xIiwibG9naW4iOiJzdG9yZTM5MDMwIiwicGFzc3dkIjoicHN3MzkwMzAifQ==";
    return false; */
    console.log(
      "createCassa =>\nStore = " +
      Store +
      "\nCassa = " +
      Num_Cassa +
      "\nMac_Address: " +
      Mac_Address
    );
    this.ngxLoader.start();
    var fullRC = "2"; //rigenera cassa full
    var str_json =
      '{"obj":"RigeneraCassa","method_name":"","dim_cod":"LOGRC","id_area":"196","id_nonno":"6","nome_colonna":"XGRANT613","modulo":"RigeneraCassa","package":"Store","center":"Rigenera cassa","boxId":"2","bookmark":"#!/Store/Punti vendita/Rigenera cassa/","center_dett":"nuovo","src":"1","tipovista":"all","centerDettDoSrc":"moduliViews","tipo_versione":"null","chiave":"codice","tipo":"valore","campoId":"LOGRC_ID","order":"   CDATA desc ","init_id_campo":"LOGRC_ID","num_rows":"0","init_offset":"null","init_curPage":"null","init_abilitaMultidel":"true","colonnaCheckBox":"true","divVisualizza":"listTableModulo","nomeFormVisualizza":"formVisualizzaModulo","nomeFormSrc":"formRicercaModulo","colonnaBottoni":"true","centerDett":"visualizza","newIsHidden":"false","LOGRC_ID":"-1","cond":"","new_callback":"dettaglioTab();","centro":"Rigenera cassa","modrul":{"lista":" AND (TB_ANAG_LOGRC00. IN (2 ) OR (TB_ANAG_LOGRC00. = 0 AND TB_ANAG_LOGRC00. = 0))"},"formName":"formDettaglioModulo","check_traslate":"0","params_html":"","tipo_campo":"cod_html|gestionePvCassa"}';
    //var WEBPATH = "https://ristorantedagigi.labketch-app.it/";
    //var WEBPATH = "https://developer.labketch-app.it:30010";
    var web_path = "";
    // var Store = "99999";
    // var Num_Cassa = "29";

    var url_server = web_path + "/moduli.php"; //"https://ristorantedagigi.labketch-app.it/moduli.php"
    var pdv = Store + "_" + Num_Cassa; //"99999_29" ;
    var macaddress = Mac_Address; //"dev_" + Num_Cassa; //"dev_29";

    var timestamp = Math.floor(Date.now() / 1000); //TODO unix time stamp deve venire dal server

    var jsonstring = JSON.parse(str_json);

    var qstring = "";

    jsonstring.center_dett = P2R_CENTER_DETT.VISUALIZZA;
    for (var t in jsonstring) {
      qstring +=
        "&method_name=createToken&" +
        t +
        "=" +
        encodeURIComponent(jsonstring[t]);
    }
    qstring += "&pdv=" + pdv;
    qstring += "&macaddress=" + macaddress;

    var tck_time = "";

    const headers = { "content-type": "application/x-www-form-urlencoded" };
    this.http
      .post(url_server, qstring, {
        headers: headers,
        observe: "response",
        responseType: "text",
      })
      .subscribe({
        next: (resp) => {
          console.log(JSON.stringify(resp));
          var token = resp.body;
          // console.log(resp['headers'].get('date'));

          let server_date_str = resp["headers"].get("date");
          var server_date = new Date(server_date_str);
          timestamp = Math.floor(server_date.getTime() / 1000);

          var url = window.location.origin;
          var urlLastDigit = url.substr(url.length - 1);
          var urlApplication = "";
          if (urlLastDigit == "p") {
            urlApplication = url.substr(0, url.length - 1);
          } else {
            urlApplication = url;
          }
          url =
            urlApplication +
            "/application/setup.php?__t=" +
            timestamp +
            "&__tkn=" +
            token;
          console.log(url);

          tck_time = url;

          //http://ls.p2r.it/application/setup.php?__t=1497541614995&__tkn=eyJtYWNhZGRyZXNzIjoiU3RvcmUzOTAzMF8xIiwibG9naW4iOiJzdG9yZTM5MDMwIiwicGFzc3dkIjoicHN3MzkwMzAifQ==

          var today = server_date;
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yy = String(today.getFullYear());
          let date = dd + "/" + mm + "/" + yy;

          this.saveTokenOnServer(
            url_server,
            token,
            tck_time,
            fullRC,
            Store,
            Num_Cassa,
            timestamp,
            date
          );
        },
        error: (error) => {
          this.ngxLoader.stopAll();
          console.error("There was an error!", error);
        },
      });
  }

  saveTokenOnServer(
    url_server,
    token,
    tck_time,
    fullRC,
    pv,
    cassa,
    timestamp,
    date
  ) {
    var str_json =
      '{"obj":"RigeneraCassa","method_name":"","dim_cod":"LOGRC","id_area":"196","id_nonno":"6","nome_colonna":"XGRANT613","modulo":"RigeneraCassa","package":"Store","center":"Rigenera cassa","boxId":"2","bookmark":"#!/Store/Punti vendita/Rigenera cassa/","center_dett":"nuovo","src":"1","tipovista":"all","centerDettDoSrc":"moduliViews","tipo_versione":"null","chiave":"codice","tipo":"valore","campoId":"LOGRC_ID","order":"   CDATA desc ","init_id_campo":"LOGRC_ID","num_rows":"0","init_offset":"null","init_curPage":"null","init_abilitaMultidel":"true","colonnaCheckBox":"true","divVisualizza":"listTableModulo","nomeFormVisualizza":"formVisualizzaModulo","nomeFormSrc":"formRicercaModulo","colonnaBottoni":"true","centerDett":"visualizza","newIsHidden":"false","LOGRC_ID":"-1","cond":"","new_callback":"dettaglioTab();","centro":"Rigenera cassa","modrul":{"lista":" AND (TB_ANAG_LOGRC00. IN (2 ) OR (TB_ANAG_LOGRC00. = 0 AND TB_ANAG_LOGRC00. = 0))"}}';
    var jsonstring = JSON.parse(str_json);

    var str_json_data =
      '{"datapicker_XLOGRC05":"XLOGRC05","offset":"","curPage":"","order":"","dim_cod":"LOGRC","nome_colonna":"XGRANT613","id_area":"196","id_nonno":"6","center":"Rigenera cassa","modulo":"RigeneraCassa","XLOGRC01":"2","XLOGRC02":"2","check_XLOGRC03_Store":"not null","check_XLOGRC05_Data":"not null"}';

    var qstring = "";

    jsonstring.center_dett = "donuovo";
    var qstring = "";
    for (var t in jsonstring) {
      qstring += "&" + t + "=" + encodeURIComponent(jsonstring[t]);
    }
    qstring += "&" + "XLOGRC06" + "=" + fullRC;

    let json_data_obj = JSON.parse(str_json_data);

    json_data_obj["XLOGRC03"] = pv;
    json_data_obj["XLOGRC04"] = cassa;
    json_data_obj["token"] = token;
    json_data_obj["XLOGRC07"] = timestamp;
    json_data_obj["tck_time"] = tck_time;
    json_data_obj["XLOGRC05"] = date;
    json_data_obj["XLOGRC06"] = fullRC;

    var qstring_data = "";
    for (var t in json_data_obj) {
      qstring_data += "&" + t + "=" + encodeURIComponent(json_data_obj[t]);
    }

    const headers = { "content-type": "application/x-www-form-urlencoded" };
    this.http
      .post(url_server + "?" + qstring, qstring_data, {
        headers: headers,
        observe: "response",
        responseType: "text",
      })
      .subscribe({
        next: (resp) => {
          console.log(JSON.stringify(resp));
          let str = resp.body;
          if (str != null && str != "") {
            str = "<base>";
            str += resp.body;
            str += "</input>";
            str += "</base>";
          }

          let parser = new DOMParser();
          let xmlDoc = parser.parseFromString(str, "text/xml");
          let esito = 0;
          esito = parseInt(
            xmlDoc.getElementsByName("esitoInsert")[0].getAttribute("value")
          );

          if (esito > 0) {
            var tokenDaPassare = tck_time;
            try {
              var soloToken = token;
              var URLDIBASETOKEN = tck_time.replace(soloToken, "");
              var token_da_farcire = atob(soloToken);
              var objToken = JSON.parse(token_da_farcire);
              objToken.tipoRegen = fullRC;
              tokenDaPassare = URLDIBASETOKEN + btoa(JSON.stringify(objToken));
              console.log("URL + Token -> " + tokenDaPassare);
              this.deskToken = tokenDaPassare;
            } catch (_errorToken) {
              console.error(_errorToken);
              var token_da_farcire = "{}";
              tokenDaPassare = tck_time;
            }
            this.saveRecreateDesk(
              url_server,
              jsonstring,
              pv,
              cassa,
              date,
              fullRC,
              timestamp,
              tokenDaPassare
            );
          }
        },
        error: (error) => {
          this.ngxLoader.stopAll();
          console.error("There was an error!", error);
        },
      });
  }

  saveRecreateDesk(
    url_server,
    jsonstring_in,
    pv,
    cassa,
    date,
    fullRC,
    timestamp_in,
    tokenDaPassare
  ) {
    var store = pv; //jQuery('#XLOGRC03').val() ;
    var device = cassa; //jQuery('#XLOGRC04').val() ;
    var data = date; //jQuery('#XLOGRC05').val() ;
    var modalita = fullRC; //jQuery('[name="XLOGRC06"]:checked').val() ;
    var timestamp = timestamp_in; //jQuery('#XLOGRC07').val() ;
    let jsonstring = jsonstring_in;

    /* genero query string da json string */
    var qstring = "";

    jsonstring.center_dett = P2R_CENTER_DETT.VISUALIZZA;

    console.log(jsonstring);

    for (var t in jsonstring) {
      if (t == "method_name") {
      } else {
        qstring += "&" + t + "=" + encodeURIComponent(jsonstring[t]);
      }
    }

    //qstring += "&" + jQuery('#RigeneraCassa_form').serialize();
    qstring += "&" + "";

    var method_name = "";
    if (modalita == 2 || modalita == "2") {
      method_name = "salvaRigeneraCassa";
    }
    if (modalita == 3 || modalita == "3") {
      method_name = "rigenera_full_nodownload";
    }

    qstring +=
      "&method_name=" +
      method_name +
      "&pdv=" +
      store +
      "_" +
      device +
      "&store=" +
      store +
      "&device=" +
      device +
      "&data=" +
      data +
      "&modalita=" +
      modalita +
      "&timestamp=" +
      timestamp;

    const headers = { "content-type": "application/x-www-form-urlencoded" };
    this.http
      .post(url_server, qstring, {
        headers: headers,
        observe: "response",
        responseType: "text",
      })
      .subscribe({
        next: (resp) => {
          console.log(JSON.stringify(resp));
          this.deskToken = tokenDaPassare;
          this.ngxLoader.stopAll();
        },
        error: (error) => {
          this.ngxLoader.stopAll();
          console.error("There was an error!", error);
        },
      });
  }

  public downloadAsPDF() {
    const pdfTable = this.pdfTable.nativeElement;

    html2canvas(pdfTable).then((canvas) => {
      var imgWidth = 150;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jsPDF("p", "mm", "a5");
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      let namefile =
        "store-" + this.storeSelected + "_desk-" + this.deskSelected + ".pdf";
      pdf.save(namefile);
    });

    /* doc.fromHTML(pdfTable.innerHTML, 15, 15, {
      width: 190,
      elementHandlers: specialElementHandlers,
    });

    doc.save("qrcode.pdf"); */
  }
  openrigenera() {
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'open-rigenera-cassa-dialog',
      body: AlertComponent,
      hasHead: false,
      data: {
        imgSrc: this.utilityService.getImg("icons/attenzione.svg"),
        title: this.adTranslateServiceService.get("attention"),
        messages: [
          this.adTranslateServiceService.get('subtitle-rige')
          ,
          { class: 'mt-3', text: ' ' },
          this.adTranslateServiceService.get('subtitle-rige-2')
        ]
      },
      actions: [
        {
          label: this.adTranslateServiceService.get("annulla"),
          click: () => {
            alert.dismiss();

            //resolve(true);
          },
          classes: "btn rounded-pill text-uppercase new-alert-button",
          data: {
            remove: true,
          },
          params: {},
        },
        {
          label: this.adTranslateServiceService.get("generate"),
          click: () => {
            alert.dismiss();
            this.recreateDesk1(
              this.storeSelected,
              this.deskSelected,
              this.deskMacAddressSelected
            );
            //resolve(false);
          },
          classes: "btn rounded-pill text-uppercase new-alert-button",
          params: {},
        },
      ],
    });
  }
  openCopyLink() {
    const alert = this.p2rDialogService.open(P2RDialogComponent, {
      id: 'open-copy-link-dialog',
      body: AlertComponent,
      hasHead: false,
      data: {
        imgSrc: this.utilityService.getImg("confirm.svg"),
        title: this.adTranslateServiceService.get("link-copiato"),
        messages: [this.adTranslateServiceService.get("subtitle-link-copiato")],
      },
      actions: [
        {
          label: this.adTranslateServiceService.get("close"),
          click: () => {
            alert.dismiss();

            //resolve(true);
          },
          classes: "btn rounded-pill text-uppercase new-alert-button",
          data: {
            remove: true,
          },
          params: {},
        },
      ],
    });
  }
}
