import { Injectable } from "@angular/core";
import { P2REnvironmentService } from "@p2r/env";
import { P2RApiService } from "@p2r/platform-api";
import { forkJoin, of } from "rxjs";
import { map } from "rxjs/operators";
import { assetUrl2 } from "src/single-spa/asset-url";
import { DrawerMenuItem } from "./dto/drawer-menu-item.model";
export class Envmod {
  API_VERSION: string;
  AUTH_PATH: string;
  BASE_URL: string;
  BASE_CONFIG_URL: string;
  COPYRIGHT: string;
  CORE_PATH: string;
  CUSTOMER: string;
  DEBUG_MODE: string;
  DEFAULT_LANG: string;
  DEFAULT_ROUTE: string;
  DEFAULT_TABLE_PAGE_SIZE: string;
  ambID: string;
}
@Injectable({
  providedIn: "root",
})
export class UtilityserviceService {
  userInfo(): any {
    return this.p2rApiService
      .get(`${this.p2rApiService.apiPath}/core/getProfileInfo`)
      .pipe(
        map((e: any) => {
          return e.session.grant_session;
        })
      );
  }
  env: Envmod;
  public name: any;
  getname: any;
  constructor(
    private p2rEnvironmentService: P2REnvironmentService,
    private p2rApiService: P2RApiService
  ) {}

  loderapp() {
    if (this.env == undefined)
      this.getname = this.p2rApiService
        .get(`${this.p2rApiService.apiPath}/configuration/store/getStoreInfo`)
        .pipe(
          map((data: any) => {
            const datatemp = data;
            localStorage.setItem("USER_STORES", JSON.stringify(datatemp));
            let name: string;
            if (!datatemp.multistore) {
              name = datatemp.pv[0].nome;
            } else {
              name = window.location.hostname.split(".")[0];
            }
            this.name = name;
            return name;
          })
        );

    this.p2rEnvironmentService.getEnvironmentStream().subscribe((res: any) => {
      this.env = res;
    });
  }

  public getImg(imgPath: string) {
    return assetUrl2(imgPath, this.env.BASE_CONFIG_URL);
  }

  public getDrawerMenu() {
    return this.p2rApiService
      .get(assetUrl2("/config/drawer_menu.json", this.env.BASE_CONFIG_URL), {})
      .pipe(
        map((v: any) => {
          const drawerMenu: Array<DrawerMenuItem> = [];
          v.forEach(
            (element: {
              type: string;
              link: any;
              submenu: DrawerMenuItem[];
            }) => {
              let item: DrawerMenuItem = {
                title: element.type,
                type: element.type,
                link: element.link,
                opensubmenu: false,
                submenu:
                  element.submenu != undefined
                    ? element.submenu.map((e) => {
                        e.title = e.type;
                        e.submenu = [];
                        return e;
                      })
                    : [],
              };

              drawerMenu.push(item);
            }
          );
          return drawerMenu;
        })
      );
  }
  getWelcome(path: any): any {
    if (path == "genera-cassa") {
      return of(path);
    }
    if (this.name == undefined) {
      return this.getname;
    } else {
      return of(this.name);
    }
  }
}
