import { Component, NgZone } from "@angular/core";
import { AdTranslateServiceService } from "./ad-translate-service.service";
import { UtilityserviceService } from "./utilityservice.service";

@Component({
  selector: "adminmenu-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "keepupapp";
  constructor(
    private utilityserviceService: UtilityserviceService,
    private ngZone: NgZone,
    private adTranslateServiceService: AdTranslateServiceService
  ) {
    this.utilityserviceService.loderapp();

    const css = `
              @font-face {
                  font-family: Roboto-Bold;
                  src: url(${this.utilityserviceService.getImg(
                    "/fonts/ROBOTO-BOLD.TTF"
                  )});
              }

              @font-face {
                  font-family: Roboto-Light;
                  src: url(${this.utilityserviceService.getImg(
                    "/fonts/ROBOTO-LIGHT.TTF"
                  )});
              }

              @font-face {
                  font-family: Roboto-Medium;
                  src: url(${this.utilityserviceService.getImg(
                    "/fonts/ROBOTO-MEDIUM.TTF"
                  )});
              }

              @font-face {
                  font-family: Roboto-Regular;
                  src: url(${this.utilityserviceService.getImg(
                    "/fonts/ROBOTO-REGULAR.TTF"
                  )});
              }
              @font-face {
        font-family: 'data-table';
        src: url(${this.utilityserviceService.getImg("fonts/data-table.eot")});
        src: url(${this.utilityserviceService.getImg(
          "fonts/data-table.eot?#iefix"
        )}) format('embedded-opentype'),
             url(${this.utilityserviceService.getImg(
               "fonts/data-table.woff"
             )}) format('woff'),
             url(${this.utilityserviceService.getImg(
               "fonts/data-table.ttf"
             )}) format('truetype'),
             url(${this.utilityserviceService.getImg(
               "fonts/data-table.svg#data-table"
             )}) format('svg');
        font-weight: normal;
        font-style: normal;
      }

              .font-light {
                  font-family: "Roboto-Light";
              }
              .font-regular {
                  font-family: "Roboto-Regular";
              }
              .font-bold {
                  font-family: "Roboto-Bold";
              }
              .font-medium {
                  font-family: "Roboto-Medium";
              }

              .rigenera .ng-select .ng-arrow-wrapper .ng-arrow {
                    display: none;
            }
           .rigenera .ng-select .ng-arrow-wrapper {
                  height: 36px;
                    width: 36px;
                    background-image: url('${this.utilityserviceService.getImg(
                      "/icons/select_arrow.svg"
                    )}');
                    background-repeat: no-repeat;
                    margin-right: 10px;
                    margin-left: 12px;
                    margin-top: 23px;
                }
.rigenera .ng-select.ng-select-opened  .ng-arrow-wrapper {
    transform: rotate(
180deg);
    margin-top: -20px;
}

    `;
    const head = document.getElementsByTagName("head")[0];
    const style = document.createElement("style");
    style.type = "text/css";
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
    this.ngZone.runOutsideAngular(() => {
      if (document.getElementById("strangleramd")) {
        document
          .getElementById("strangleramd")
          .setAttribute("src", "/node_modules/systemjs/dist/extras/amd.min.js");
      }
    });
  }
}
