import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AdTranslateServiceService } from "src/app/ad-translate-service.service";
import { DrawerMenuItem } from "src/app/dto/drawer-menu-item.model";

@Component({
  selector: "keepup-link-menu",
  templateUrl: "./link-menu.component.html",
  styleUrls: ["./link-menu.component.scss"],
})
export class LinkMenuComponent implements OnInit {
  item: DrawerMenuItem;
  @Output() clickItemlink = new EventEmitter<DrawerMenuItem>();
  type: number;
  constructor(private adTranslateServiceService: AdTranslateServiceService) { }
  @Input("item")
  set setitem(value: DrawerMenuItem) {
    if (value != undefined) {
      value.title = this.adTranslateServiceService.get(value.title);
      this.item = value;
    }
  }
  @Input("type")
  set settype(value: number) {
    if (value != undefined) {
      this.type = value;
    }
  }
  clickitem(item) {
    this.clickItemlink.emit(item);
  }

  ngOnInit() { }
}
