import { APP_BASE_HREF } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { KeepupEmptyRouteComponent } from "./empty-route/keepup-empty-route.component";
import { LayoutComponent } from "./layout/layout.component";
import {
  MenuResolverService,
  RigeneraResolverService,
  WelcomeResolverService,
} from "./menu-resolver.service";
import { RigeneraCassaHistoryComponent } from "./rigenera-cassa/rigenera-cassa-history/rigenera-cassa-history.component";
import { RigeneraCassaLayoutComponent } from "./rigenera-cassa/rigenera-cassa-layout/rigenera-cassa-layout.component";
import { RigeneraCassaComponent } from "./rigenera-cassa/rigenera-cassa.component";
import { WelcomeComponent } from "./welcome/welcome.component";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    resolve: {
      container: MenuResolverService,
    },

    children: [
      {
        path: "",
        component: WelcomeComponent,
        resolve: {
          container: WelcomeResolverService,
        },
      },
      {
        path: "genera-cassa",
        component: RigeneraCassaLayoutComponent,
        resolve: {
          container: RigeneraResolverService,
        },
        children: [
          {
            path: "",
            component: RigeneraCassaComponent,
            resolve: {
              container: WelcomeResolverService,
            },
          },
          {
            path: "history",
            component: RigeneraCassaHistoryComponent,
            resolve: {
              container: WelcomeResolverService,
            },
          },
        ],
      },

      { path: "**", component: KeepupEmptyRouteComponent },
    ],
  },
];

/*   resolve: {
      container: GmUsersListres,
    }, */

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: "/" }],
})
export class AppRoutingModule {}
