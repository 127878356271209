import { P2REnvironmentService } from "@p2r/env";
import { assetUrl } from "../single-spa/asset-url";
import { HttpLoaderFactory } from "./ad-translate-service.service";
const ɵ0 = HttpLoaderFactory, ɵ1 = (envService) => () => envService
    .loadEnvironment({
    config_url: assetUrl("config/config.json"),
    env_url: `${window[`app_base`] ? window[`app_base`] : ""}/assets/config/env.json`,
    microfrontend: "adminmenu",
})
    .toPromise();
export class AppModule {
}
export { ɵ0, ɵ1 };
