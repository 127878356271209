<div class="w-100 pl-12 ">
    <div class="row">
        <div class="col-12">
            <keepup-block-user [user]="user"></keepup-block-user>
        </div>
    </div>
    <div class="row">

        <div class="col-12">
            <keepup-link-menu
                (clickItemlink)="selectItem($event,-1)"
                [item]="itemhome"
                [type]="0"
            ></keepup-link-menu>
            <ng-container *ngFor="let item of drawerMenu; index as i">

                <keepup-link-menu
                    (clickItemlink)="selectItem($event,i)"
                    [item]="item"
                    [type]="0"
                ></keepup-link-menu>

                <ng-container *ngIf="item.opensubmenu">
                    <ng-container *ngFor="let itemsub of item.submenu">

                        <keepup-link-menu
                            (clickItemlink)="selectItem($event,i)"
                            [item]="itemsub"
                            [type]="1"
                        ></keepup-link-menu>

                    </ng-container>
                </ng-container>
            </ng-container>
            <keepup-link-menu
                (clickItemlink)="logout()"
                [item]="itemlogout"
                [type]="0"
            ></keepup-link-menu>
        </div>
    </div>

</div>
