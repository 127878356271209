import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { P2RDialogModel, POSITION, SIZE } from './p2r-dialog.interface';

export const P2R_DIALOG_DEFAULT_CONFIG: P2RDialogModel = {
    centered: true,
    backdrop: 'static',
    backdropClass: 'modal-backdrop',
    animation: true,
    keyboard: true,
    hideCloseButton: false,
    closeOnOutsideClick: false,
    size: 'md',
    scrollable: true
};

/**
 * Injectable
 * P2RDialogService
 * @example
 *  p2rDialogService.open(
 *                  P2RDialogComponent,
 *                 {
 *                  title: 'Sei sicuro di cancellare le modifiche attuali?',
 *                  position: undefined,
 *                  size: undefined,
 *                  frame: true,
 *                  body: undefined,
 *                  side: true,
 *                  actions:
 *                       [
 *                           { label: 'Confirm',
 *                             click: undo.bind(this),
 *                             classes: 'btn btn-success rounded-0',
 *                             params: { position: 'right', close: true } },
 *                          { label: 'Undo', click: () => { },
 *                            classes: 'btn btn-warning rounded-0',
 *                            params: { position: 'left', dismiss: true } }
 *                        ] });
 *
 */
@Injectable()
export class P2RDialogService {

    constructor(
        private ngbModal: NgbModal
    ) { }
    /**
     * Opens p2 rdialog service
     * @param componentOrTemplate
     * @param options
     * @returns open
     */
    public open(componentOrTemplate: any, options: P2RDialogModel): any {

        const modal = this.ngbModal.open(componentOrTemplate,
            {
                backdrop: options.backdrop !== undefined ? options.backdrop : P2R_DIALOG_DEFAULT_CONFIG.backdrop,
                backdropClass: options.backdropClass !== undefined ? options.backdropClass : P2R_DIALOG_DEFAULT_CONFIG.backdropClass,
                centered: options.centered !== undefined ? options.centered : P2R_DIALOG_DEFAULT_CONFIG.centered,
                keyboard: options.keyboard !== undefined ? options.keyboard : P2R_DIALOG_DEFAULT_CONFIG.keyboard,
                // windowClass: this.getModalWindowClass({
                //   position: options.position
                // }),
                // size: this.getModalDialogClass({
                //   size: options.size,
                //   position: options.position,
                //   frame: options.frame
                // }) as 'lg'
                size: options.size ? options.size as 'sm' : '' as 'sm'
            });
        modal.componentInstance.options = options;

        return modal;
    }

    private getModalDialogClass(opt): string {
        let classes = '';

        switch (opt.size) {
            case SIZE.SMALL:
                classes = classes.concat('sm');
                break;
            case SIZE.MEDIUM:
                classes = classes.concat('md');
                break;
            case SIZE.LARGE:
                classes = classes.concat('lg');
                break;
            case SIZE.FLUID:
                classes = classes.concat('fluid');
                break;
            default:
                classes = classes.concat('sm');
                break;
        }

        classes = classes.concat(' ');

        switch (opt.position) {
            case POSITION.TOP:
                classes = classes.concat('modal-top');
                break;
            case POSITION.BOTTOM:
                classes = classes.concat('modal-bottom');
                break;
            default:
                break;
        }

        classes = classes.concat(' ');

        if (opt.frame) {
            classes = classes.concat('modal-frame');
        }

        return classes;
    }

}
