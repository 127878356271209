/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "../utilityservice.service";
import * as i5 from "@angular/common";
import * as i6 from "../menu/menu.component.ngfactory";
import * as i7 from "../menu/menu.component";
import * as i8 from "@p2r/platform-api";
import * as i9 from "@angular/router";
import * as i10 from "./layout.component";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
function View_LayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "keepup-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.UtilityserviceService, i5.DOCUMENT], { setTile: [0, "setTile"], setDate: [1, "setDate"], setTypeheader: [2, "setTypeheader"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.name; var currVal_1 = _co.date; var currVal_2 = _co.typeheader; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["id", "container-all"], ["style", "overflow: hidden; height: 100%;"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "disabled-header": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 16, "div", [["class", " container-body  overflow-auto"], ["id", "container-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", " menu"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "show": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "keepup-menu", [], null, null, null, i6.View_MenuComponent_0, i6.RenderType_MenuComponent)), i1.ɵdid(12, 114688, null, 0, i7.MenuComponent, [i8.P2RApiService, i4.UtilityserviceService, i9.Router], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", " app-container p-0 overflow-x"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closemenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(15, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(16, { "d-none": 0 }), (_l()(), i1.ɵeld(17, 0, null, null, 5, "div", [["class", " p-0 h-100 overflow-x"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closemenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(19, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(20, { "d-none": 0 }), (_l()(), i1.ɵeld(21, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(22, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.disabledheder); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.disabledheder; _ck(_v, 5, 0, currVal_1); var currVal_2 = " menu"; var currVal_3 = _ck(_v, 10, 0, _co.navbarOpen); _ck(_v, 9, 0, currVal_2, currVal_3); _ck(_v, 12, 0); var currVal_4 = " app-container p-0 overflow-x"; var currVal_5 = _ck(_v, 16, 0, (_co.name != "")); _ck(_v, 15, 0, currVal_4, currVal_5); var currVal_6 = " p-0 h-100 overflow-x"; var currVal_7 = _ck(_v, 20, 0, (_co.name == "")); _ck(_v, 19, 0, currVal_6, currVal_7); _ck(_v, 22, 0); }, null); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "keepup-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 114688, null, 0, i10.LayoutComponent, [i4.UtilityserviceService, i9.ActivatedRoute, i9.Router, i5.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i1.ɵccf("keepup-layout", i10.LayoutComponent, View_LayoutComponent_Host_0, {}, {}, []);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
