import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateLoader, TranslateService } from "@ngx-translate/core";
import { P2REnvironmentService } from "@p2r/env";
import { LocalStorageService } from "ngx-webstorage";
import { of } from "rxjs";
import { zip } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { assetUrl } from "src/single-spa/asset-url";

export function HttpLoaderFactory(
  http: HttpClient,
  environment: P2REnvironmentService
) {
  return new P2RTranslationLoader(http, environment);
}

export class P2RTranslationLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private environment: P2REnvironmentService
  ) { }
  public getTranslation(lang: string): any {
    return zip(
      this.http.get(
        assetUrl(
          `i18n/${lang}.json`,
          this.environment.getEnvironment().BASE_CONFIG_URL
        )
      )
    ).pipe(
      map(([t1, t2]) => ({
        ...t1,
        ...t2,
      }))
    );
  }
}
@Injectable({
  providedIn: "root",
})
export class AdTranslateServiceService {
  environment: any;

  constructor(
    public translate: TranslateService,
    private p2rEnvironmentService: P2REnvironmentService,
    private localStorageService: LocalStorageService
  ) {
    this.environment = this.p2rEnvironmentService.getEnvironment();

    let lang =
      (window.sessionStorage.getItem("lingua") &&
        window.sessionStorage.getItem("lingua").toLocaleLowerCase()) ||
      (this.localStorageService.retrieve("lingua") &&
        (
          this.localStorageService.retrieve("lingua") as string
        ).toLocaleLowerCase()) ||
      this.environment.DEFAULT_LANG ||
      this.translate.getBrowserLang();

    if (lang === "us") {
      lang = "en";
    }

    this.translate.setDefaultLang(lang);
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  onLanguageChange$(key: string | string[], interpolateParams?: any) {
    return this.translate.getStreamOnTranslationChange(key, interpolateParams);
  }

  get(string) {
    return this.translate.instant(string);
  }
}
