import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { P2REnvironmentService } from "@p2r/env";
import { P2rPlatformApiLibModule } from "@p2r/platform-api";
import {
  LocalStorageService,
  NgxWebstorageModule,
  SessionStorageService,
} from "ngx-webstorage";
import { assetUrl } from "../single-spa/asset-url";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { EmptyRouteComponent } from "./empty-route/empty-route.component";
import { KeepupEmptyRouteComponent } from "./empty-route/keepup-empty-route.component";
import { LayoutComponent } from "./layout/layout.component";
import { WelcomeComponent } from "./welcome/welcome.component";
import { MFECommunicationModule } from "@p2r/mfe";
import { SingleSpaPropsSubject } from "../single-spa/single-spa-props";
import { MenuComponent } from "./menu/menu.component";
import { LinkMenuComponent } from "./menu/link-menu/link-menu.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "./ad-translate-service.service";
import { BlockUserComponent } from "./menu/block-user/block-user.component";
import { RigeneraCassaComponent } from "./rigenera-cassa/rigenera-cassa.component";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { QRCodeModule } from "angular2-qrcode";
import { RigeneraCassaHistoryComponent } from "./rigenera-cassa/rigenera-cassa-history/rigenera-cassa-history.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { HeaderComponent } from "./layout/header/header.component";
import { RigeneraCassaLayoutComponent } from "./rigenera-cassa/rigenera-cassa-layout/rigenera-cassa-layout.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "./shared/shared.module";

@NgModule({
  declarations: [
    AppComponent,
    KeepupEmptyRouteComponent,
    EmptyRouteComponent,
    LayoutComponent,
    WelcomeComponent,
    MenuComponent,
    LinkMenuComponent,
    BlockUserComponent,
    RigeneraCassaComponent,
    RigeneraCassaHistoryComponent,
    HeaderComponent,
    RigeneraCassaLayoutComponent,
  ],
  imports: [
    NgxDatatableModule,
    QRCodeModule,
    NgxUiLoaderModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    MFECommunicationModule.forRoot({
      channelName: "adminmenuChannel",
      singleSPAPropsSubject: SingleSpaPropsSubject,
    }),
    NgxWebstorageModule.forRoot(),

    SharedModule,
    P2rPlatformApiLibModule.forRoot({
      LOCAL_STORAGE_SERVICE: LocalStorageService,
      SESSION_STORAGE_SERVICE: SessionStorageService,
      P2R_ENVIRONMENT_SERVICE: P2REnvironmentService,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, P2REnvironmentService],
      },
    }),
  ],

  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: P2REnvironmentService) => () =>
        envService
          .loadEnvironment({
            config_url: assetUrl("config/config.json"),
            env_url: `${
              window[`app_base`] ? window[`app_base`] : ""
            }/assets/config/env.json`,
            microfrontend: "adminmenu",
          })
          .toPromise(),
      deps: [P2REnvironmentService],
      multi: true,
    },
    P2REnvironmentService,
    SingleSpaPropsSubject,
    { provide: "MICROFRONTEND", useValue: "adminmenu" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
