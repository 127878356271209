<div class="d-flex flex-grow-1 h-100">
    <div
        class="left-half position-relative"
        style="overflow: auto;"
    >
        <ul class="properties">
            <li
                *ngFor="let property of properties"
                [ngStyle]="{'display': (property.label != undefined) ? 'visible' : 'none'}"
                [class.selected]="property.isSelected"
                (click)="onSelectProperty(property)"
            >
                <div class="position-relative">
                    <div class="label-uppercase">{{property.label}}</div>
                </div>
            </li>
        </ul>
    </div>
    <div
        class="right-half"
        style="overflow: auto;"
    >
        <router-outlet></router-outlet>
    </div>
</div>
