import { Output } from "@angular/core";
import { Component, OnInit, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { P2RApiService } from "@p2r/platform-api";
import { forkJoin } from "rxjs";
import { DrawerMenuItem } from "../dto/drawer-menu-item.model";
import { User } from "../dto/users.model";
import { UtilityserviceService } from "../utilityservice.service";

@Component({
  selector: "keepup-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  @Output() clickItem = new EventEmitter<any>();
  drawerMenu: DrawerMenuItem[];
  itemhome: DrawerMenuItem;
  itemlogout: DrawerMenuItem;
  user: User;

  constructor(
    private p2rApiService: P2RApiService,
    private utilityService: UtilityserviceService,
    private router: Router
  ) {
    this.itemhome = {} as DrawerMenuItem;
    this.itemhome.title = "home";
    this.itemhome.type = "home";
    this.itemhome.link = "/";
    this.itemhome.submenu = [];
    this.itemlogout = {} as DrawerMenuItem;
    this.itemlogout.title = "logout";
    this.itemlogout.type = "logout";
    this.itemlogout.link = "/login";
    this.itemlogout.submenu = [];
  }

  ngOnInit() {
    forkJoin([
      this.utilityService.getDrawerMenu(),
      this.utilityService.userInfo(),
    ]).subscribe((res) => {
      if (res[0]) {
        this.drawerMenu = res[0] as DrawerMenuItem[];
      }

      this.user = res[1] as User;
    });
  }

  selectItem(value: DrawerMenuItem, index: number) {
    if (value.submenu.length > 0) {
      this.drawerMenu[index].opensubmenu = !this.drawerMenu[index].opensubmenu;
    } else {
      if (value.type != "home") {
        document.dispatchEvent(new CustomEvent("SETTINGS:CLOSE_MENU"));
      }

      this.router.navigate([value.link]);
    }
  }
  logout() {
    window.location.href = "/login";
  }
}
