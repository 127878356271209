import { of } from "rxjs";
import { map } from "rxjs/operators";
import { assetUrl2 } from "src/single-spa/asset-url";
import * as i0 from "@angular/core";
import * as i1 from "@p2r/env";
import * as i2 from "@p2r/platform-api";
export class Envmod {
}
export class UtilityserviceService {
    constructor(p2rEnvironmentService, p2rApiService) {
        this.p2rEnvironmentService = p2rEnvironmentService;
        this.p2rApiService = p2rApiService;
    }
    userInfo() {
        return this.p2rApiService
            .get(`${this.p2rApiService.apiPath}/core/getProfileInfo`)
            .pipe(map((e) => {
            return e.session.grant_session;
        }));
    }
    loderapp() {
        if (this.env == undefined)
            this.getname = this.p2rApiService
                .get(`${this.p2rApiService.apiPath}/configuration/store/getStoreInfo`)
                .pipe(map((data) => {
                const datatemp = data;
                localStorage.setItem("USER_STORES", JSON.stringify(datatemp));
                let name;
                if (!datatemp.multistore) {
                    name = datatemp.pv[0].nome;
                }
                else {
                    name = window.location.hostname.split(".")[0];
                }
                this.name = name;
                return name;
            }));
        this.p2rEnvironmentService.getEnvironmentStream().subscribe((res) => {
            this.env = res;
        });
    }
    getImg(imgPath) {
        return assetUrl2(imgPath, this.env.BASE_CONFIG_URL);
    }
    getDrawerMenu() {
        return this.p2rApiService
            .get(assetUrl2("/config/drawer_menu.json", this.env.BASE_CONFIG_URL), {})
            .pipe(map((v) => {
            const drawerMenu = [];
            v.forEach((element) => {
                let item = {
                    title: element.type,
                    type: element.type,
                    link: element.link,
                    opensubmenu: false,
                    submenu: element.submenu != undefined
                        ? element.submenu.map((e) => {
                            e.title = e.type;
                            e.submenu = [];
                            return e;
                        })
                        : [],
                };
                drawerMenu.push(item);
            });
            return drawerMenu;
        }));
    }
    getWelcome(path) {
        if (path == "genera-cassa") {
            return of(path);
        }
        if (this.name == undefined) {
            return this.getname;
        }
        else {
            return of(this.name);
        }
    }
}
UtilityserviceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilityserviceService_Factory() { return new UtilityserviceService(i0.ɵɵinject(i1.P2REnvironmentService), i0.ɵɵinject(i2.P2RApiService)); }, token: UtilityserviceService, providedIn: "root" });
