import { UtilisShards } from "./../utils/UtilsShared";
import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnInit } from "@angular/core";

import { UtilityserviceService } from "src/app/utilityservice.service";

@Component({
  selector: "keepup-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  imgfullscreen: string;
  imgreductscreen: string;
  isFullScreen: boolean;
  iconmenu: string;
  title: string;
  menu: any;
  isopenmenu: any;
  date: any;
  typeheader: any = 0;
  @Input("title")
  set setTile(value: string) {
    if (value != undefined) {
      this.title = value;
    }
  }
  @Input("date")
  set setDate(value: any) {
    if (value != undefined) {
      this.date = value;
    }
  }
  @Input("typeheader")
  set setTypeheader(value: any) {
    if (value != undefined) {
      this.typeheader = value;
    }
  }
  constructor(
    private utilityserviceService: UtilityserviceService,
    @Inject(DOCUMENT) private doc: any
  ) {
    this.imgfullscreen = this.utilityserviceService.getImg(
      "icons/fullscreen-icon.svg"
    );
    this.imgreductscreen = this.utilityserviceService.getImg(
      "icons/windowscreen-icon.svg"
    );
    this.isFullScreen = document.fullscreenElement ? true : false;
    this.iconmenu = this.utilityserviceService.getImg("icons/menu_1.png");
  }
  ngOnInit() { }
  toggleNavbar() {
    if (this.isopenmenu == undefined || this.isopenmenu == false) {
      document.dispatchEvent(new CustomEvent("SETTINGS:OPEN_MENU"));
      this.isopenmenu = true;
    } else {
      this.isopenmenu = false;
      document.dispatchEvent(new CustomEvent("SETTINGS:CLOSE_MENU"));
    }
  }
  openFullscreen() {
    UtilisShards.openFullscreen(this.doc.documentElement);
    this.isFullScreen = true;
  }
  closeFullscreen() {
    UtilisShards.closeFullscreen(this.doc);
    this.isFullScreen = false;
  }
}
