import { of } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "./utilityservice.service";
export class MenuResolverService {
    constructor(utilityserviceService) {
        this.utilityserviceService = utilityserviceService;
    }
    resolve(route) {
        const routetemp = route._urlSegment;
        let router = routetemp;
        console.log("routetemp", router);
        if (router.parent == null || router.segments[0].path == "genera-cassa") {
            if (router.segments[0] != undefined) {
                return this.utilityserviceService.getWelcome(router.segments[0].path);
            }
            else {
                return this.utilityserviceService.getWelcome("");
            }
        }
        else {
            return of("");
        }
    }
}
MenuResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuResolverService_Factory() { return new MenuResolverService(i0.ɵɵinject(i1.UtilityserviceService)); }, token: MenuResolverService, providedIn: "root" });
export class WelcomeResolverService {
    constructor(utilityserviceService) {
        this.utilityserviceService = utilityserviceService;
    }
    resolve(route) {
        const routetemp = route._urlSegment;
        let router = routetemp;
        return this.utilityserviceService.getWelcome("");
    }
}
WelcomeResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WelcomeResolverService_Factory() { return new WelcomeResolverService(i0.ɵɵinject(i1.UtilityserviceService)); }, token: WelcomeResolverService, providedIn: "root" });
export class RigeneraResolverService {
    constructor(utilityserviceService) {
        this.utilityserviceService = utilityserviceService;
    }
    resolve(route) {
        const routetemp = route._urlSegment;
        let router = routetemp;
        return of(router.segments);
    }
}
RigeneraResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RigeneraResolverService_Factory() { return new RigeneraResolverService(i0.ɵɵinject(i1.UtilityserviceService)); }, token: RigeneraResolverService, providedIn: "root" });
