export class UtilisShards {
  public static openFullscreen(elem: any) {
    let isOpened: boolean;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      isOpened = true;
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
      isOpened = true;
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
      isOpened = true;
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
      isOpened = true;
    }
  }

  public static closeFullscreen(doc: any) {
    let isClosed: boolean;
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
      isClosed = true;
    } else if (doc.mozCancelFullScreen) {
      /* Firefox */
      doc.mozCancelFullScreen();
      isClosed = true;
    } else if (doc.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      doc.webkitExitFullscreen();
      isClosed = true;
    } else if (doc.msExitFullscreen) {
      /* IE/Edge */
      doc.msExitFullscreen();
      isClosed = true;
    }
  }
}
