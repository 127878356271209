<div
    class="d-flex flex-column"
    style="overflow: hidden; margin: 16px;"
>
    <div class="text-center">
        <ng-container *ngIf="imgSrc">
            <div
                id="image-container"
                class="image-container"
            >
                <img
                    [src]="imgSrc"
                    height="80px"
                    width="80px"
                >
            </div>
            <div class="vertical-space"></div>
        </ng-container>
        <ng-container *ngIf="title">
            <div
                id="title-container"
                class="title-container fs34 font-medium"
            >
                {{title}}
            </div>
            <div class="vertical-space"></div>
        </ng-container>
        <div class="message-container">
            <ng-container *ngFor="let message of messages">
                <div [ngClass]="message.class ? message.class : 'message-container m-auto w-75'">
                    <span class="font-r_l fs18 lh21 c11">{{message.text || message}}</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>
