import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { P2RDialogComponent } from "./admin-dialog/p2r-dialog.component";
import { P2RDialogService } from "./admin-dialog/p2r-dialog.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AlertComponent } from "./alert/alert.component";

@NgModule({
  declarations: [P2RDialogComponent, AlertComponent],
  entryComponents: [P2RDialogComponent, AlertComponent],
  imports: [CommonModule, NgbModule],
  providers: [P2RDialogService],
  exports: [P2RDialogComponent, NgbModule, AlertComponent],
})
export class SharedModule {}
