/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rigenera-cassa-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./rigenera-cassa-layout.component";
import * as i5 from "../../ad-translate-service.service";
var styles_RigeneraCassaLayoutComponent = [i0.styles];
var RenderType_RigeneraCassaLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RigeneraCassaLayoutComponent, data: {} });
export { RenderType_RigeneraCassaLayoutComponent as RenderType_RigeneraCassaLayoutComponent };
function View_RigeneraCassaLayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelectProperty(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "display": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "label-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_1 = _ck(_v, 3, 0, ((_v.context.$implicit.label != undefined) ? "visible" : "none")); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.isSelected; _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.context.$implicit.label; _ck(_v, 6, 0, currVal_2); }); }
export function View_RigeneraCassaLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "d-flex flex-grow-1 h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "left-half position-relative"], ["style", "overflow: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "properties"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RigeneraCassaLayoutComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "right-half"], ["style", "overflow: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.properties; _ck(_v, 4, 0, currVal_0); _ck(_v, 7, 0); }, null); }
export function View_RigeneraCassaLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "keepup-rigenera-cassa-layout", [], null, null, null, View_RigeneraCassaLayoutComponent_0, RenderType_RigeneraCassaLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i4.RigeneraCassaLayoutComponent, [i5.AdTranslateServiceService, i3.Router, i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RigeneraCassaLayoutComponentNgFactory = i1.ɵccf("keepup-rigenera-cassa-layout", i4.RigeneraCassaLayoutComponent, View_RigeneraCassaLayoutComponent_Host_0, {}, {}, []);
export { RigeneraCassaLayoutComponentNgFactory as RigeneraCassaLayoutComponentNgFactory };
