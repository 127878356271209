/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./link-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./link-menu.component";
import * as i4 from "../../ad-translate-service.service";
var styles_LinkMenuComponent = [i0.styles];
var RenderType_LinkMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LinkMenuComponent, data: {} });
export { RenderType_LinkMenuComponent as RenderType_LinkMenuComponent };
export function View_LinkMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-12 link-menu font-regular"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickitem(_co.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "submenu-pr": 0 }), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "col-12 link-menu font-regular"; var currVal_1 = _ck(_v, 4, 0, (_co.type == 1)); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.title; _ck(_v, 5, 0, currVal_2); }); }
export function View_LinkMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "keepup-link-menu", [], null, null, null, View_LinkMenuComponent_0, RenderType_LinkMenuComponent)), i1.ɵdid(1, 114688, null, 0, i3.LinkMenuComponent, [i4.AdTranslateServiceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LinkMenuComponentNgFactory = i1.ɵccf("keepup-link-menu", i3.LinkMenuComponent, View_LinkMenuComponent_Host_0, { setitem: "item", settype: "type" }, { clickItemlink: "clickItemlink" }, []);
export { LinkMenuComponentNgFactory as LinkMenuComponentNgFactory };
