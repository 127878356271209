import { Injectable } from "@angular/core";
import { UrlSegmentGroup } from "@angular/router";
import { Resolve } from "@angular/router";
import { of } from "rxjs";
import { UtilityserviceService } from "./utilityservice.service";

@Injectable({
  providedIn: "root",
})
export class MenuResolverService implements Resolve<any> {
  constructor(private utilityserviceService: UtilityserviceService) {}
  resolve(route: any): any {
    const routetemp = route._urlSegment as UrlSegmentGroup;
    let router = routetemp;
    console.log("routetemp", router);
    if (router.parent == null || router.segments[0].path == "genera-cassa") {
      if (router.segments[0] != undefined) {
        return this.utilityserviceService.getWelcome(router.segments[0].path);
      } else {
        return this.utilityserviceService.getWelcome("");
      }
    } else {
      return of("");
    }
  }
}

@Injectable({
  providedIn: "root",
})
export class WelcomeResolverService implements Resolve<any> {
  constructor(private utilityserviceService: UtilityserviceService) {}
  resolve(route: any): any {
    const routetemp = route._urlSegment as UrlSegmentGroup;
    let router = routetemp;

    return this.utilityserviceService.getWelcome("");
  }
}

@Injectable({
  providedIn: "root",
})
export class RigeneraResolverService implements Resolve<any> {
  constructor(private utilityserviceService: UtilityserviceService) {}
  resolve(route: any): any {
    const routetemp = route._urlSegment as UrlSegmentGroup;
    let router = routetemp;

    return of(router.segments);
  }
}
