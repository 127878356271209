import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UtilityserviceService } from "../utilityservice.service";

@Component({
  selector: "keepup-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent implements OnInit {
  public imag = "backgroundBO.svg";
  public imglogoWelcome = "logoKeepUp.svg";
  imgreductscreen: string;
  imgfullscreen: string;
  name: any;
  constructor(
    private utilityService: UtilityserviceService,
    private route: ActivatedRoute
  ) {
    this.imag = this.utilityService.getImg(this.imag);
    this.imglogoWelcome = this.utilityService.getImg(this.imglogoWelcome);
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      console.log(data);
      const datatemp = data.container;
      this.name = datatemp;
    });
  }
}
