<!-- per nessun  motivo toglire  style="overflow: hidden;" -->
<div
    id="container-all"
    [ngClass]="{ 'disabled-header': disabledheder }"
    style="overflow: hidden; height: 100%;"
>
    <!-- <div class="row header ">
    <div class="col-12 justify-content-between d-flex">
      <div class="align-items-center d-flex" (click)="toggleNavbar()">
        <img class="img-24" [src]="iconmenu">
        <div class="test-header font-regular " *ngIf="name!=''">
          <span> {{ date | date: 'dd.MM.yyyy'}}</span>
          <span class="ml-32"> {{name}} - Back office</span>
        </div>
      </div>
      <div class="d-flex" *ngIf="isFullScreen==false">
        <img [src]="imgfullscreen" alt="" class="h-fit mt-auto mb-auto" (click)="openFullscreen()">
      </div>
      <div class="d-flex" *ngIf="isFullScreen==true">
        <img [src]="imgreductscreen" alt="" class="h-fit mt-auto mb-auto" (click)="closeFullscreen()">

      </div>

    </div>
  </div> -->
    <keepup-header
        [title]="name"
        [date]="date"
        [typeheader]="typeheader"
        *ngIf="!disabledheder"
    ></keepup-header>

    <div
        class=" container-body  overflow-auto"
        id="container-body"
    >
        <div
            class=" menu"
            [ngClass]="{ 'show': navbarOpen }"
        >
            <keepup-menu></keepup-menu>
        </div>
        <div
            [ngClass]="{ 'd-none': name!='' }"
            class=" app-container p-0 overflow-x"
            (click)="closemenu()"
        >

        </div>
        <div
            [ngClass]="{ 'd-none': name=='' }"
            class=" p-0 h-100 overflow-x"
            (click)="closemenu()"
        >
            <router-outlet></router-outlet>
        </div>

    </div>
</div>
