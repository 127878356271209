<div class="d-flex flex-grow-1 flex-column h-100 overflow-hidden">
    <div
        style="margin: 2px;"
        class="data-table-df h-100"
    >
        <ngx-datatable
            class="material h-100 "
            [rows]="rows"
            [columnMode]="ColumnMode.force"
            [headerHeight]='64'
            [scrollbarV]="true"
            [footerHeight]='0'
            [rowHeight]='64'
        >
            <ngx-datatable-column
                name="{{'store' | translate}}"
                prop="XLOGRC03"
            ></ngx-datatable-column>
            <ngx-datatable-column
                name="{{'desk' | translate}}"
                prop="XLOGRC04"
            ></ngx-datatable-column>
            <ngx-datatable-column
                name="{{'date' | translate}}"
                prop="XLOGRC05"
                [sortable]="false"
            ></ngx-datatable-column>
            <ngx-datatable-column
                name="{{'time' | translate}}"
                prop="XLOGRC07"
            ></ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
