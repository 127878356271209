import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  ChangeDetectorRef,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { P2RDialogModel, SIZE } from "./p2r-dialog.interface";
import { P2R_DIALOG_DEFAULT_CONFIG } from "./p2r-dialog.service";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "p2r-dialog",
  templateUrl: "./p2r-dialog.component.html",
  styleUrls: ["./p2r-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class P2RDialogComponent implements OnInit, OnDestroy {
  private _options: P2RDialogModel;
  private _title: string;
  private _titleClasses: string;
  private _size: SIZE | string;
  private _hideCloseButton: boolean;
  private _centered: boolean;
  private _backdrop: boolean | "static";
  private _animation: boolean;
  private _keyboard: boolean;
  private _hasHead: boolean;
  private _closeOnOutsideClick: boolean;
  private _backdropClass: string;
  private _data: any;
  private _actions: any;
  private _scrollable: boolean;
  private _body: any;
  private _loader: string;
  private componentInstance: any;
  private _id: string;

  private actionsClassificationCriteria = ["left", "right"];

  classificatedActions: {
    [key: string]: Array<any>;
  };

  classificationsLength = 0;

  @ViewChild("bodyContainer", { read: ViewContainerRef, static: true })
  bodyContainer: ViewContainerRef;
  public numberAction: any;

  constructor(
    private activeModal: NgbActiveModal,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdRef: ChangeDetectorRef
  ) {
    this.classificatedActions = _.zipObject(this.actionsClassificationCriteria);
  }

  ngOnInit() {}

  @Input() set options(val: P2RDialogModel) {
    console.log(val);
    this._options = val
      ? Object.assign({}, P2R_DIALOG_DEFAULT_CONFIG, val)
      : Object.assign({}, P2R_DIALOG_DEFAULT_CONFIG);

    if (this.options.body) {
      if (this.options.body instanceof TemplateRef) {
        this.bodyContainer.createEmbeddedView(this.options.body, {
          data: this.options.data,
        });
      } else {
        const componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            this.options.body
          ) as any;
        this.componentInstance = this.bodyContainer.createComponent(
          componentFactory
        ).instance as any;
        this.componentInstance.data = this.options.data;
      }
    }

    this.classificatedActions = this.actionsClassification(
      this._options.actions
    );
    this.numberAction = this._options.actions.length;

    if (!this.cdRef[`destroyed`]) {
      this.cdRef.detectChanges();
    }
  }

  get options(): P2RDialogModel {
    return this._options;
  }

  @Input() set title(val: string) {
    this._title = val;
  }

  get title(): string {
    return this._title
      ? this._title
      : this._options.title instanceof Function
      ? this._options.title()
      : this._options.title;
  }

  @Input() set id(val: string) {
    this._id = val;
  }

  get id(): string {
    return this._id ? this._id : this._options.id;
  }

  @Input() set titleClasses(val: string) {
    this._titleClasses = val;
  }

  get titleClasses(): string {
    return this._titleClasses
      ? this._titleClasses
      : this._options.titleClasses instanceof Function
      ? this._options.titleClasses()
      : this._options.titleClasses;
  }

  @Input() set loader(val: string) {
    this._loader = val;
  }

  get loader(): string {
    return this._loader ? this._loader : this._options.loader;
  }

  @Input() set size(val: string | SIZE) {
    this._size = val;
  }

  get size(): string | SIZE {
    return this._size ? this._size : this._options.size;
  }

  @Input() set hideCloseButton(val: boolean) {
    this._hideCloseButton = val;
  }

  get hideCloseButton(): boolean {
    return this._hideCloseButton
      ? this._hideCloseButton
      : this._options.hideCloseButton;
  }

  @Input() set centered(val: boolean) {
    this._centered = val;
  }

  get centered(): boolean {
    return this._centered ? this._centered : this._options.centered;
  }

  @Input() set backdrop(val: boolean | "static") {
    this._backdrop = val;
  }

  get backdrop(): boolean | "static" {
    return this._backdrop ? this._backdrop : this._options.backdrop;
  }

  @Input() set animation(val: boolean) {
    this._animation = val;
  }

  get animation(): boolean {
    return this._animation ? this._animation : this._options.animation;
  }

  @Input() set keyboard(val: boolean) {
    this._keyboard = val;
  }

  get keyboard(): boolean {
    return this._keyboard ? this._keyboard : this._options.keyboard;
  }

  @Input() set hasHead(val: boolean) {
    this._hasHead = val;
  }

  get hasHead(): boolean {
    return this._hasHead ? this._hasHead : this._options.hasHead;
  }

  @Input() set closeOnOutsideClick(val: boolean) {
    this._closeOnOutsideClick = val;
  }

  get closeOnOutsideClick(): boolean {
    return this._closeOnOutsideClick
      ? this._closeOnOutsideClick
      : this._options.closeOnOutsideClick;
  }

  @Input() set backdropClass(val: string) {
    this._backdropClass = val;
  }

  get backdropClass(): string {
    return this._backdropClass
      ? this._backdropClass
      : this._options.backdropClass;
  }

  get data(): any {
    return this._data || this._options.data;
  }

  @Input() set data(val: any) {
    this._data = val;
  }

  @Input() set actions(val: []) {
    this._actions = val;
    this.classificatedActions = this.actionsClassification(this._actions);
  }

  get actions(): [] {
    return this._actions ? this._actions : this._options.actions;
  }

  @Input() set scrollable(val: boolean) {
    this._scrollable = val;
  }

  get scrollable(): boolean {
    return this._scrollable ? this._scrollable : this._options.scrollable;
  }

  @Input() set body(val: any) {
    this._body = val;
  }

  get body(): any {
    return this._body ? this._body : this._options.body;
  }

  public dismiss(cb) {
    this.activeModal.dismiss(this.data);
    if (cb && typeof cb === "function") {
      cb();
    }
  }

  public close(cb) {
    this.activeModal.close(this.data);
    if (cb && typeof cb === "function") {
      cb();
    }
  }

  private actionsClassification(actions: Array<any>) {
    const grouped = _.groupBy(actions, (data) => {
      return data.params.position;
    });

    this.classificationsLength = Object.keys(grouped).length;

    return grouped;
  }

  private bindToDialogDefaultActions(action) {
    const actionBindingByParam = (ac) => {
      if (ac.params.dismiss) {
        this.dismiss = this.dismiss.bind(this, ac.click.bind(null, ac.data));
        return this.dismiss;
      } else if (ac.params.submit && ac.params.close) {
        this.close = this.close.bind(
          this,
          ac.click.bind(null, ac.data, this.body)
        );
        return this.close;
      } else if (ac.params.close) {
        this.close = this.close.bind(this, ac.click.bind(null, ac.data));
        return this.close;
      } else if (ac.params.submit) {
        return ac.click.bind(null, {
          data: ac.data,
          formData: this.componentInstance.form,
        });
      } else {
        return ac.click;
      }
    };

    return {
      ...action,
      click: actionBindingByParam(action),
    };
  }

  ngOnDestroy() {
    // if (this.componentRef) {
    //   this.componentRef.destroy();
    //   this.componentRef = null;
    // }
  }
}
